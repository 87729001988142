import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../core';

@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AuthLayoutComponent {

    constructor(private userService: AuthenticationService,
        private router: Router) { }

    logOut() {
        this.userService.logOut();

        this.router.navigate(['login']);
    }
}
