// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.ruby.ai',
  GOOGLE_PUBLIC_KEY: '6LfvnIoUAAAAADh1C35R91xAsaNljwSFmY6ohNlI',
  GOOGLE_SECRET_KEY: '6LfvnIoUAAAAAJZCftk0G9B9iY9X6hjmhA5fAvql',
  GOOGLE_SCORE_LIMIT: '0.5'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
