import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ValidationService } from './valdiators/validation.service';
import { ControlMessagesComponent } from './valdiators/control-message.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ControlMessagesComponent
    ],
    exports: [
        ControlMessagesComponent
    ],
    entryComponents: [
    ],
    providers: [
        ValidationService
    ]
})
export class HelpersModule { }
