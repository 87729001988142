import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class AuthenticationService {

    constructor(
        private localStorageService: LocalStorageService
    ) { }

    isAuthorized() {
        return !!this.getCurrentUser();
    }

    getCurrentUser() {
        const userData = this.localStorageService.get('userData');

        return userData ? JSON.parse(userData) : null;
    }

    setCurrentUser(userData: any) {
        this.localStorageService.set('userData', JSON.stringify(userData));
    }

    isAdmin() {
        let isAdmin = false;

        const userData = this.getCurrentUser();

        if (userData && userData.email === 'yoela@77labs.com') {
            isAdmin = true;
        }

        return isAdmin;
    }

    logOut() {
        this.setCurrentUser(null);
        return true;
    }
}
