import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './validation.service';

@Component({
    selector: 'app-control-messages',
    template: `<div class="error-wrapper" [ngStyle]="{'background-color': bgColor}" *ngIf="errorMessage !== null"><p class="error-message">
    <i class="fas fa-exclamation-circle"></i>{{errorMessage}}</p></div>`,
    styleUrls: ['./control-message.component.scss']
})
export class ControlMessagesComponent implements OnInit {

    @Input() submitted: boolean;
    @Input() control: FormControl;
    @Input() validationMessage: string;
    @Input() public bgColor: string;

    private defaultBgColor = 'rgba(235, 87, 87, 0.16)';

    constructor() { }

    ngOnInit(): void {
        if (!this.bgColor) {
            this.bgColor = this.defaultBgColor;
        }
    }

    get errorMessage() {
        for (const propertyName in this.control.errors) {
            if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.submitted)) {
                let message = '';
                if (this.validationMessage) {
                    message = this.validationMessage;
                } else {
                    message = ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
                }

                return message;
            }
        }

        return null;
    }
}
