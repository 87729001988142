import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeComponent } from './home/home.component';
import { RetailComponent } from './retail/retail.component';
import { GoComponent } from './go/go.component';
import { GoNewComponent } from './go-new/go-new.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ThankYouUploadComponent } from './thank-you-upload/thank-you-upload.component';
import { ThankYouRequestDemoComponent } from './thank-you-request-demo/thank-you-request-demo.component';
import { UploadComponent } from './upload/upload.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { RegisterService } from './register/register.service';
import { LoginService } from './login/login.service';
import { RequestDemoService } from './request-demo/request-demo.service';
import { HelpersModule } from '../helpers/helpers.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		HelpersModule
	],
	declarations: [
		HomeComponent,
		RetailComponent,
		ThankYouComponent,
		ThankYouUploadComponent,
		UploadComponent,
		RegisterComponent,
		LoginComponent,
		GoComponent,
		GoNewComponent,
		RequestDemoComponent,
		ThankYouRequestDemoComponent
	],
	exports: [
		HomeComponent,
		UploadComponent,
		RegisterComponent,
		LoginComponent
	],
	entryComponents: [
	],
	providers: [
		RegisterService,
		LoginService,
		RequestDemoService
	]
})
export class PublicModule { }
