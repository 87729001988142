import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { RegisterService } from './register.service';
import { LoginService } from '../login/login.service';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;

    constructor(private fb: FormBuilder,
        private registerService: RegisterService,
        private loginService: LoginService,
        private router: Router,
        private authService: AuthenticationService) { }

    ngOnInit() {
        this.registerForm = this.fb.group({
            first_name: ['', [Validators.required]],
            last_name: ['', [Validators.required]],
            company: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        if (this.authService.isAuthorized()) {
            this.router.navigate(['app']);
        }
    }

    register() {
        this.submitted = true;

        if (this.registerForm.dirty && this.registerForm.valid) {
            const userData = {
                first_name: this.registerForm.value.first_name,
                last_name: this.registerForm.value.last_name,
                company: this.registerForm.value.company,
                email: this.registerForm.value.email,
                password: this.registerForm.value.password
            };

            this.registerService.register(userData).subscribe(response => {
                if (response && response.status === 200) {

                    const loginData = {
                        email: userData.email,
                        password: userData.password
                    };

                    this.loginService.login(loginData).subscribe(loginRes => {
                        if (loginRes && loginRes.status === 200) {
                            this.router.navigate(['/app/projects/create']);
                        }
                    });
                }
            });
        }
    }
}
