import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/authentication.service';


@Injectable()
export class ProjectService {

    constructor(
        private http: HttpClient,
        private userService: AuthenticationService
    ) {
    }

    getProjects(): Observable<any> {

        const url = `${environment.apiUrl}/project`;

        return this.http.get(url);
    }

    getProject(id): Observable<any> {
        const url = `${environment.apiUrl}/project/${id}`;

        return this.http.get(url);
    }

    createProject(projectData): Observable<any> {

        const url = `${environment.apiUrl}/project`;
        const currentUser = this.userService.getCurrentUser();

        projectData.email = currentUser.email;
        projectData.user_id = currentUser.id;

        return this.http.post(url, projectData);
    }

    addProjectData(projectId, projectData): Observable<any> {

        const url = `${environment.apiUrl}/project/${projectId}/addData`;

        return this.http.post(url, projectData);
    }

    getProjectReport(projectId): Observable<any> {
        const url = '/assets/chart-data/sample-report-data.json';

        return this.http.get(url);
    }
}
