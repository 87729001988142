import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';

import { ProjectService } from '../project.service';
import { ProjectInterface } from '../project.interface';

@Component({
    selector: 'app-project-report',
    templateUrl: './project-report.component.html',
    styleUrls: ['./project-report.component.scss']
})
export class ProjectReportComponent implements OnInit {

    @ViewChild(BaseChartDirective) chartComponent: BaseChartDirective;
    legendData: any;

    projectId: string;
    showSampleData = false;
    reportData: any;
    viewingSubclasses = false;
    project: any;

    public doughnutChartLabels: string[] = [];
    public doughnutChartData: number[] = [];
    public doughnutChartType = 'doughnut';
    public doughnutColors: Array<any> = [];


    private getLegendCallback = (function (self) {
        function handle(chart) {
            // Do stuff here to return an object model.
            // Do not return a string of html or anything like that.
            // You can return the legendItems directly or
            // you can use the reference to self to create
            // an object that contains whatever values you
            // need to customize your legend.
            return chart.legend.legendItems;
        }
        return function (chart) {
            return handle(chart);
        };
    })(this);

    public chartOptions: any = {
        legend: { position: 'bottom' },
        legendCallback: this.getLegendCallback
    };

    public chartColors: any = [{
        backgroundColor: ['#df5db2', '#c64499', '#9a3577',
            '#9e5ddf', '#8544c6', '#5d7ddf', '#113193', '#001779', '#4464c6', '#2A4AAC',
            '#354e9a', '#405288', '#26386e', '#0D1F55', '#172242']
    }];

    constructor(private projectService: ProjectService,
        private route: ActivatedRoute,
        private router: Router,
        private cdRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.projectId = this.route.snapshot.params.id;

        this.route.queryParamMap
            .subscribe((params: any) => {
                if (params && params.params.sample) {
                    this.showSampleData = true;
                }
            });
        if (!this.showSampleData) {
            // TODO: Implement this
        }

        this.projectService.getProject(this.projectId).subscribe(res => {
            if (res) {
                this.project = res.data;
            }
        });

        this.projectService.getProjectReport(this.projectId).subscribe(res => {
            if (res) {
                this.reportData = res;

                this.doughnutChartLabels = this.reportData.labels;
                this.doughnutColors = this.reportData.colors;
                this.doughnutChartData = this.reportData.data;

                this.cdRef.detectChanges();
                this.legendData = this.chartComponent.chart.generateLegend();
            }
        });
    }

    // events
    public chartClicked(e: any): void {
        // TODO: Implement custom logic if needed
    }

    public goToProjectCreate(): void {
        this.router.navigate(['/app'], { queryParams: { projectId: this.projectId } });
    }
}
