import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectService } from '../project.service';
import { ProjectInterface } from '../project.interface';

@Component({
    selector: 'app-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {

    project: ProjectInterface;

    constructor(private projectService: ProjectService,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        const projectId = this.route.snapshot.params.id;

        if (projectId) {
            this.projectService.getProject(projectId).subscribe(res => {
                if (res) {
                    this.project = res.data;
                }
            });
        }
    }

    viewSampleReport() {
        this.router.navigate(['app/projects/report', this.project.id], { queryParams: { sample: true } });
    }

    viewReport() {

    }
}
