import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.userService.logOut();
                location.reload(true);
            } else {
                if (err.error.errors && err.error.errors.length > 0) {
                    const message = this.prepareErrorMessage(err.error.errors[0]);

                    if (message) {
                        alert(message);
                    }
                }
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }

    prepareErrorMessage(error): string {
        let errorMessage = '';

        if (error && error.value) {
            switch (error.value) {
                case 'invalidEmailPasswordErr': {
                    errorMessage = 'Invalid username or password.';
                    break;
                }
                case 'emailInUseErr': {
                    errorMessage = 'Email is already in use.';
                    break;
                }
                case 'couldNotCreateUserErr': {
                    errorMessage = 'An error occurred during the registration. Please try again!';
                    break;
                }
            }
        }

        return errorMessage;
    }
}
