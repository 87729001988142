import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { PublicModule } from './public-pages/public.module';
import { AuthPagesModule } from './auth-pages/auth-pages.module';
import { AuthenticationService } from './core/authentication.service';
import { LocalStorageService } from './core/local-storage.service';
import { JwtInterceptor, ErrorInterceptor } from './core';

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    AuthLayoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    PublicModule,
    AuthPagesModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LocalStorageService,
    AuthenticationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
