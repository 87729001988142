import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../core/authentication.service';
import { LoginService } from './login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;

    constructor(private fb: FormBuilder,
        private userService: AuthenticationService,
        private loginService: LoginService,
        private router: Router) { }

    ngOnInit() {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });

        if (this.userService.isAuthorized()) {
            this.router.navigate(['app']);
        }
    }

    login() {
        this.submitted = true;

        if (this.loginForm.dirty && this.loginForm.valid) {
            const userData = {
                email: this.loginForm.value.email,
                password: this.loginForm.value.password
            };

            this.loginService.login(userData).subscribe(response => {
                if (response && response.status === 200) {
                    this.router.navigate(['app']);
                }
            });
        }
    }
}
