import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

import { environment } from '../../../environments/environment';
import { ProjectService } from '../project.service';
import { ProjectInterface } from '../project.interface';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
    selector: 'app-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, AfterViewInit {

    projects: ProjectInterface[];
    companyName: string;
    showUploadControl = true;

    additionalDataUrl: string;
    activeAdditionalProjectId: string;
    addDataErrorMessage: string;

    isAdmin: boolean;

    constructor(private projectService: ProjectService,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private modalService: NgbModal) { }

    ngOnInit() {
        const currentUser = this.authService.getCurrentUser();

        if (currentUser.company) {
            this.companyName = currentUser.company;
        }

        this.isAdmin = this.authService.isAdmin();

        this.projectService.getProjects().subscribe(res => {
            if (res) {
                this.projects = res.data;

                // Expand the project box. We need all projects in order for this to work.
                this.route.queryParamMap
                    .subscribe((params: any) => {
                        if (params && params.params.projectId) {

                            const selectedProjectId = Number(params.params.projectId);

                            for (const project of this.projects) {
                                if (project.id === selectedProjectId) {
                                    project.isCollapsed = !project.isCollapsed;
                                }
                            }
                        }
                    });
            }
        });

        this.route.queryParamMap
            .subscribe((params: any) => {
                if (params && params.params.emailValidated) {
                    alert('Your email has been validated!');
                }
                if (params && params.params.projectId) {
                    // Navigate to project and open it
                }
            });
    }

    ngAfterViewInit() {

    }

    goToProjectCreate() {
        this.router.navigate(['/app/projects/create']);
    }

    viewSampleReport(project) {
        this.router.navigate(['app/projects/report', project.id], { queryParams: { sample: true } });
    }

    viewReport(project) {
        this.router.navigate(['app/projects/report', project.id], { queryParams: { sample: false } });
    }

    addAdditionalData(projectId, modalContent) {

        this.additionalDataUrl = '';
        this.activeAdditionalProjectId = projectId;

        this.modalService.open(modalContent,
            { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                this.router.navigate(['app']);
            }, (reason) => {
                this.router.navigate(['app']);
            });

        const that = this;

        const fileUploaderOptions = {
            maxFilesize: 100,
            maxFiles: 2,
            thumbnailWidth: 25,
            timeout: 1200000,
            previewTemplate: '<div class="dz-preview dz-file-preview">' +
                '<div class="dz-details">' +
                '<div class="dz-image-custom"><img src="/assets/images/icons/upload-files-icon.svg" alt="icon" /></div>' +
                '<div class="dz-file-details-wrapper">' +
                '<div class="dz-filename"><span data-dz-name></span></div>' +
                '<div class="dz-size"><span data-dz-size=""></span></div>' +
                '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
                '</div>' +
                '</div>' +
                '<div class="dz-success-mark"><i class="fas fa-check"></i></div>' +
                '<div class="dz-error-mark"><i class="fas fa-times"></i></div>' +
                '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
                '</div>',
            url: environment.apiUrl + '/upload-file',
            acceptedFiles: '.csv, .json, .txt',
            init() {
                this.on('success', function (file, response) {
                    if (response) {
                        that.addDataErrorMessage = '';

                        if (that.additionalDataUrl) {
                            that.additionalDataUrl += ', ' + response;
                        } else {
                            that.additionalDataUrl = response;
                        }

                        that.addAdditionalFiles();
                    }
                });

                this.on('error', function (file, response) {
                    console.log('error');
                    console.log(response);
                });
            }
        };

        // @ts-ignore: Unreachable code error
        let sampleFilesUploader = new Dropzone('div#sampleFiles', fileUploaderOptions);

        $('div#sampleFiles').addClass('dropzone');
    }

    addFiles() {
        if (!this.showUploadControl) {
            this.showUploadControl = true;
        }
    }

    addUrls() {
        if (this.showUploadControl) {
            this.showUploadControl = false;
        }
    }

    addAdditionalFiles() {
        this.addDataErrorMessage = '';
        if (this.additionalDataUrl) {

            const projectData = {
                additional_data_urls: this.additionalDataUrl
            };

            this.projectService.addProjectData(this.activeAdditionalProjectId, projectData).subscribe(response => {
                if (response && response.status === 200 && response.data) {
                    this.modalService.dismissAll();

                    for (const project of this.projects) {
                        if (project.id === response.data.id) {
                            project.additionalDataSources = response.data.additionalDataSources;
                        }
                    }
                }
            });
        } else {
            this.addDataErrorMessage = 'You have to upload or give us the url to at least one file.';
        }
    }
}
