import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ProjectListComponent } from './project-list/project-list.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectReportComponent } from './project-report/project-report.component';
import { ValidationService } from '../helpers/valdiators/validation.service';
import { HelpersModule } from '../helpers/helpers.module';

import { ProjectService } from './project.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ChartsModule,
        NgbModule,
        HelpersModule
    ],
    declarations: [
        ProjectListComponent,
        ProjectDetailsComponent,
        ProjectCreateComponent,
        ProjectReportComponent
    ],
    exports: [
        ProjectListComponent,
        ProjectDetailsComponent,
        ProjectCreateComponent,
        ProjectReportComponent
    ],
    entryComponents: [
    ],
    providers: [
        ProjectService,
        ValidationService
    ]
})
export class AuthPagesModule { }
