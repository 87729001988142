import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-go-new',
	templateUrl: './go-new.component.html',
	styleUrls: ['./go-new.component.scss']
})
export class GoNewComponent {

	constructor(private modalService: NgbModal) { }

	getYear() {
		return new Date().getFullYear();
	}

	requestDemo(modalContent) {
		this.modalService.open(modalContent,
			{ ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			}, (reason) => {
			});
	}

}
