import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-thank-you-request-demo',
    templateUrl: './thank-you-request-demo.component.html',
    styleUrls: ['./thank-you-request-demo.component.scss']
})
export class ThankYouRequestDemoComponent {

    constructor(private modalService: NgbModal) { }

    getYear() {
        return new Date().getFullYear();
    }

    requestDemo(modalContent) {
        this.modalService.open(modalContent,
            { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            }, (reason) => {
            });
    }
}
