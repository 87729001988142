import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

declare var $: any;

import { environment } from '../../../environments/environment';

import { ProjectService } from '../project.service';
import { ProjectInterface } from '../project.interface';
import { AuthenticationService } from '../../core/authentication.service';

@Component({
    selector: 'app-project-create',
    templateUrl: './project-create.component.html',
    styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit, AfterViewInit {
    createProjectForm: FormGroup;
    project: ProjectInterface;
    showUploadControl = true;
    submitted = false;

    dataProcessingLoaderValue = 0;
    loaderIntervalId: any;

    constructor(private formBuilder: FormBuilder,
        private projectService: ProjectService,
        private authService: AuthenticationService,
        private router: Router,
        private modalService: NgbModal) { }

    ngOnInit() {
        this.createProjectForm = this.formBuilder.group({
            name: ['', [Validators.required]],
            sampleDataUrls: ['', [Validators.required]],
            industry: ['', [Validators.required]],
            dataset_type: ['', [Validators.required]],
            annotation_type: ['', [Validators.required]],
            deadline: ['']
        });
    }

    ngAfterViewInit() {
        $('.selectpicker').selectpicker('refresh');
        $('.dropdown').removeClass('dropup');

        const that = this;
        const startDate = new Date();

        startDate.setDate(startDate.getDate() + 3);

        // @ts-ignore: Unreachable code error
        $('.datepicker').datepicker({
            format: 'mm/dd/yyyy',
            startDate: startDate,
            autoclose: true
        }).on('changeDate', function (event) {
            that.createProjectForm.controls['deadline'].setValue(event.format('mm/dd/yyyy'));
        });

        const fileUploaderOptions = {
            maxFilesize: 100,
            maxFiles: 2,
            thumbnailWidth: 25,
            timeout: 1200000,
            previewTemplate: '<div class="dz-preview dz-file-preview">' +
                '<div class="dz-details">' +
                '<div class="dz-image-custom"><img src="/assets/images/icons/upload-files-icon.svg" alt="icon" /></div>' +
                '<div class="dz-file-details-wrapper">' +
                '<div class="dz-filename"><span data-dz-name></span></div>' +
                '<div class="dz-size"><span data-dz-size=""></span></div>' +
                '<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>' +
                '</div>' +
                '</div>' +
                '<div class="dz-success-mark"><i class="fas fa-check"></i></div>' +
                '<div class="dz-error-mark"><i class="fas fa-times"></i></div>' +
                '<div class="dz-error-message"><span data-dz-errormessage></span></div>' +
                '</div>',
            url: environment.apiUrl + '/upload-file',
            acceptedFiles: '.csv, .json, .txt',
            init() {
                this.on('success', function (file, response) {
                    if (response) {
                        const uploadForm = that.createProjectForm;

                        if (uploadForm.value.sampleDataUrls) {
                            const val = uploadForm.value.sampleDataUrls + ', ' + response;
                            uploadForm.controls['sampleDataUrls'].setValue(val);
                        } else {
                            uploadForm.controls['sampleDataUrls'].setValue(response);
                        }

                        const element = document.getElementById('sampleDataUrls');
                        const event = new Event('change');
                        element.dispatchEvent(event);
                    }
                });

                this.on('error', function (file, response) {
                    console.log('error');
                    console.log(response);
                });
            }
        };

        // @ts-ignore: Unreachable code error
        let sampleFilesUploader = new Dropzone('div#sampleFiles', fileUploaderOptions);

        $('div#sampleFiles').addClass('dropzone');
    }

    addFiles() {
        if (!this.showUploadControl) {
            this.showUploadControl = true;
        }
    }

    addUrls() {
        if (this.showUploadControl) {
            this.showUploadControl = false;
        }
    }

    createProject(modalContent, modalContentNotAdmin) {

        this.submitted = true;

        if (this.createProjectForm.dirty && this.createProjectForm.valid) {
            const projectData = {
                name: this.createProjectForm.value.name,
                sample_data_urls: this.createProjectForm.value.sampleDataUrls,
                industry: this.createProjectForm.value.industry,
                dataset_type: this.createProjectForm.value.dataset_type,
                annotation_type: this.createProjectForm.value.annotation_type,
                deadline: this.createProjectForm.value.deadline
            };

            this.projectService.createProject(projectData).subscribe(response => {
                if (response && response.status === 200 && response.data.id) {

                    if (this.authService.isAdmin()) {
                        this.modalService.open(modalContent,
                            {
                                ariaLabelledBy: 'modal-basic-title',
                                backdrop: 'static',
                                keyboard: false,
                                windowClass: 'create-project-modal'
                            }).result.then((result) => {
                                this.router.navigate(['app/projects/report', response.data.id], { queryParams: { sample: true } });
                            }, (reason) => {
                                this.router.navigate(['app/projects/report', response.data.id], { queryParams: { sample: true } });
                            });

                        const that = this;
                        this.loaderIntervalId = setInterval(i => {
                            if (that.dataProcessingLoaderValue < 100) {
                                that.dataProcessingLoaderValue += 10;
                            } else {
                                clearInterval(that.loaderIntervalId);
                            }
                        }, 500);
                    } else {
                        this.modalService.open(modalContentNotAdmin,
                            {
                                ariaLabelledBy: 'modal-basic-title',
                                backdrop: 'static',
                                keyboard: false,
                                windowClass: 'create-project-modal not-admin'
                            }).result.then((result) => {
                                this.router.navigate(['app'], { queryParams: { projectId: response.data.id } });
                            }, (reason) => {
                                this.router.navigate(['app'], { queryParams: { projectId: response.data.id } });
                            });
                    }
                }
            });
        }
    }
}
