import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { AuthenticationService } from '../../core/authentication.service';

@Injectable()
export class LoginService {

    constructor(
        private http: HttpClient,
        private userService: AuthenticationService
    ) { }

    login(userData): Observable<any> {
        const url = environment.apiUrl + '/login';

        return this.http.post<any>(url, userData).pipe(map(res => {
            if (res && res.status === 200 && res.data) {
                const user = {
                    token: res.data.bearerToken,
                    email: userData.email,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    company: res.data.company,
                    id: res.data.id
                };

                this.userService.setCurrentUser(user);
            }

            return res;
        }));
    }
}
