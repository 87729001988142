import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { RequestDemoService } from './request-demo.service';

@Component({
    selector: 'app-request-demo',
    templateUrl: './request-demo.component.html',
    styleUrls: ['./request-demo.component.scss']
})
export class RequestDemoComponent implements OnInit {

    @Input() buttonText: string;
    @Input() isRequestDemo: boolean;

    requestDemoForm: FormGroup;
    submitted = false;

    constructor(private fb: FormBuilder,
        private router: Router,
        private modalService: NgbModal,
        private requestDemoService: RequestDemoService) {
    }

    ngOnInit() {
        this.requestDemoForm = this.fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            company: ['', [Validators.required]]
        });

        if (!this.isRequestDemo) {
            this.isRequestDemo = false;
        }
    }

    requestDemo() {
        this.submitted = true;

        if (this.requestDemoForm.dirty && this.requestDemoForm.valid) {
            const demoData = {
                name: this.requestDemoForm.value.name,
                email: this.requestDemoForm.value.email,
                company: this.requestDemoForm.value.company,
                isRequestDemo: this.isRequestDemo,
                currentUrl: this.router.url
            };

            this.requestDemoService.requestDemo(demoData).subscribe(response => {
                if (response && response.status === 200) {
                    this.modalService.dismissAll();

                    if (this.isRequestDemo) {
                        this.router.navigate(['demo-thanks'], { queryParams: { isGo: true } });
                    } else {
                        this.router.navigate(['go-thanks']);
                    }
                }
            });
        }
    }

}
