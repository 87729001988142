import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    storage = localStorage;

    constructor() { }

    get(key: string): string {
        return this.storage.getItem(key);
    }

    set(key: string, value: string): void {
        this.storage.setItem(key, value);
    }

    remove(key: string): void {
        this.storage.removeItem(key);
    }
}
