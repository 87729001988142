import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
    public googlePublicKey: string;

    constructor() {
        this.googlePublicKey = environment.GOOGLE_PUBLIC_KEY;
    }
}
