import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

    backBtnRoute = '/';

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.queryParamMap
            .subscribe((params: any) => {
                if (params && params.params.isRetail) {
                    this.backBtnRoute = '/retail';
                }

                if (params && params.params.isGo) {
                    this.backBtnRoute = '/go';
                }
            });
    }
}
